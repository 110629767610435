import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory("/"),
  routes: [
    {
      path: "/",
      meta: { title: "前端微应用" },
      redirect: "/main",
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../view/login/login.vue"),
    },
    {
      path: "/adminLogin",
      name: "adminLogin",
      component: () => import("@/view/adminLogin"),
    },
    {
      path: "/tenants",
      name: "tenants",
      component: () => import("@/view/main/tenants"),
    },
    {
      path: "/main",
      name: "main",
      component: () => import("../view/main/main_new.vue"),
    },
    {
      path: "/lodmain",
      name: "lodmain",
      component: () => import("../view/main/main.vue"),
    },
    {
      path: "/userInfo",
      name: "userInfo",
      component: () => import("../view/profile/password.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const maToken = localStorage.getItem("maToken");
  if (!localStorage.getItem("userInfo")) {
    localStorage.setItem("userInfo", "{}");
  }
  const userInfo = localStorage.getItem("userInfo");
  if (maToken && userInfo !== "{}") {
    if (to.path === "/login" || to.path === "/adminLogin") {
      localStorage.setItem("maToken", "");
      localStorage.setItem("userInfo", "{}");
    }
    next();
  } else {
    if (
      to.path === "/login" ||
      to.path === "/adminLogin" ||
      to.path === "/superv/engineering/h5" || //监理签章
      to.path === "/attends/sign"|| //借阅签名
      to.path === "/attends/register" //简历登记
    ) {
      next();
    } else {
      console.log("to.path", to.path);
      window.location.href = "/login";
    }
  }
});

export default router;
