let globalSocket ;
const userInfo = JSON.parse(localStorage.getItem("userInfo"));
let token = userInfo ? userInfo.token : ''
let defaultUrl = process.env.VUE_APP_URL_SOCKET+"ws?token="+token
// let lockReconnect = false;//避免重复连接

export const initWebSocket = (url = defaultUrl) =>{
    globalSocket = new WebSocket(url);

    globalSocket.onopen = () => {
        console.log('WebSocket连接已建立');
    };

    globalSocket.onmessage = (event) => {
        // 处理收到的消息
        console.log('Received message:', event.data);
        // 可能需要将消息传递给子应用
        broadcastToSubApps(event.data);
    };

    globalSocket.onclose = () => {
        console.log('WebSocket连接已关闭');
    };
    window.globalSocket = globalSocket;
}


export const sendMessage = (message) => {
  if (globalSocket && globalSocket.readyState === WebSocket.OPEN) {
      console.log('要传的消息----',message);
      globalSocket.send(message);
    } else {
      console.error('WebSocket未打开.');
    }
};

export const reconnectWebSocket = (url = defaultUrl)=> {
  // if(lockReconnect) return;
  // lockReconnect = true;
  initWebSocket(url);
}

const broadcastToSubApps = (message) => {
  // 传递给子应用
    window.qiankunState = {
      ...window.qiankunState,
      websocketMessage: message,
    };
    window.DetailData = message;
    var event = new Event('DetailDataChange');
  window.dispatchEvent(event);
  // let event = new Event('qiankunStateChange');
  // window.dispatchEvent(event);
};

window.sendMessageToWebSocket = sendMessage;
window.reconnectWebSocketHandle = reconnectWebSocket;