// 主应用中注册微应用
import { registerMicroApps, start } from "qiankun";
import router from "./router";
const loader = (loading) => {
  console.log(loading);
};

console.log("process.env==", process.env);
/**
 * [基于路由配置]
 * 通过将微应用关联到一些 url 规则的方式，实现当浏览器 url 发生变化时，自动加载相应的微应用的功能。
 */
registerMicroApps(
  [
    {
      name: "attends",
      entry: process.env.VUE_APP_URL_ATTENDANCE,
      container: "#container",
      activeRule: "/attends", // 微应用的激活规则
      loader,
      props:{data:'子应用',mainAppRouter:history,router}
    },
    {
      name: "global",
      entry: process.env.VUE_APP_URL_GLOBAL,
      container: "#container",
      activeRule: (location) => location.pathname.startsWith("/global"),
      loader,
      props:{data:'子应用',mainAppRouter:history,router}
    },
    {
      name: "superv",
      entry: process.env.VUE_APP_URL_SUPERV,
      container: "#container",
      activeRule: "/superv", // 微应用的激活规则
      loader,
      props:{data:'子应用',mainAppRouter:history,router}
    },
  ],

  // 微应用的一些注册信息 lifeCycles - LifeCycles - 可选，全局的微应用生命周期钩子
  {
    beforeLoad: (app) => console.log("before load", app.name),
    beforeMount: [(app) => console.log("before mount", app.name)],
    afterMount: [(app) => console.log("after mount", app.name)],
  }
);

// setDefaultMountApp("/mx");

start({
  sandbox: {
    experimentalStyleIsolation: true,
  },
});
