import { createApp } from "vue";
import App from "./App.vue";
import router from './router';
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'nifty-style'
import '@/styles/index.scss'
import { initWebSocket } from '@/utils/globalSocket';
// import {setDomFontSizeDebounce} from '@/utils/setRem'
// setDomFontSizeDebounce()
// const userInfo = JSON.parse(localStorage.getItem("userInfo"));
// if(userInfo && userInfo.token) initWebSocket()

createApp(App).use(router).use(ElementPlus).mount("#app");
import "./registerConfig"; // 引入qiankun主应用
